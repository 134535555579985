<template>
  <div>
    <b-tabs pills>
      <b-tab title="Exonerações" active>
        <b-card-text>
          <exoneracoes-regularizadas />
        </b-card-text>
      </b-tab>

      <b-tab title="Óbitos">
        <b-card-text>
          <obitos-regularizados />
        </b-card-text>
      </b-tab>

      <b-tab title="Parcelas Regularizadas">
        <b-card-text>
          <parcelas-vencidas-regularizadas />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ExoneracoesRegularizadas from './exoneracoesRegularizadas.vue'
  import ObitosRegularizados from './obitosRegularizados.vue'
  import ParcelasVencidasRegularizadas from './parcelasVencidasRegularizadas.vue'

  export default {
    components: {
      ExoneracoesRegularizadas,
      ObitosRegularizados,
      ParcelasVencidasRegularizadas,
    },
  }
</script>
