<template>
  <b-form>
    <b-row>
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="parametro.foto != null ? parametro.foto.foto : null"
            :text="avatarText(parametro.segurado.tomador.nomeCompleto)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ parametro.segurado.tomador.nomeCompleto }}
              </h4>
              <span class="card-text">CPF: {{ parametro.segurado.tomador.cpf }}</span
              ><br />
              <span class="card-text"
                >Renegociação:
                <span class="badge badge-light-primary badge-pill">{{
                  this.formatarCodigoSequencial(parametro.codigo)
                }}</span></span
              >
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100" aria-describedby="gridRelatorio">
          <tr>
            <th class="pb-50">
              <feather-icon icon="BriefcaseIcon" class="mr-75" />
              <span class="font-weight-bold">Órgão</span>
            </th>
            <td class="pb-50">
              {{ parametro.segurado.orgao ? parametro.segurado.orgao.nome : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Categoria</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ formatarInativoAposentado(parametro.segurado.situacaoPrevidenciaria) }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefone</span>
            </th>
            <td>
              {{ parametro.segurado.tomador.telefone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="AtSignIcon" class="mr-75" />
              <span class="font-weight-bold">E-mail</span>
            </th>
            <td>
              {{ parametro.segurado.tomador.email }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="3">
        <b-form-group label="Valor do Contrato Atualizado" label-for="valor">
          <b-form-input
            id="valor"
            placeholder="Valor da Proposta"
            :value="formatarValor(parcelasAtualizadas)"
            :readonly="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Parcelas" label-for="parcelas">
          <b-form-input id="parcelas" placeholder="Parcelas" :value="parametro.numeroParcelas" :readonly="true" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Taxa %" label-for="taxa">
          <b-form-input id="taxa" placeholder="Taxa %" :value="parametro.taxas" :readonly="true" />
        </b-form-group>
      </b-col>
      <b-col md="3" v-if="!parametro.possuiAntecipacaoExonerado">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 188px"
          class="mb-2"
          @click="gerarRenegociacao()"
        >
          Gerar Renegociação
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-table
          striped
          responsive
          class="position-relative"
          :per-page="perPageVisualizarParcelas"
          :current-page="currentPageVisualizarParcelas"
          :items="itemsVisualizarParcelas"
          :fields="fieldsVisualizarParcelas"
          :sort-by.sync="sortByVisualizarParcelas"
          :sort-desc.sync="sortDescVisualizarParcelas"
          :sort-direction="sortDirectionVisualizarParcelas"
        >
          <template #cell(valor)="row">
            {{ formatarValor(row.item.valor) }}
          </template>

          <template #cell(valorAntecipacao)="row">
            {{ formatarValor(row.item.valorAntecipacao ? row.item.valorAntecipacao : '') }}
          </template>

          <template #cell(vencimento)="row">
            {{ formatarData(row.item.vencimento) }}
          </template>
        </b-table>

        <b-col md="12" v-if="itemsVisualizarParcelas.length == 0">
          <naoEncontradoModal />
        </b-col>

        <b-card-body class="d-flex justify-content-center flex-wrap pt-0" v-if="itemsVisualizarParcelas.length != 0">
          <b-pagination
            v-model="currentPageVisualizarParcelas"
            :total-rows="totalRowsVisualizarParcelas"
            :per-page="perPageVisualizarParcelas"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>

    <b-modal
      id="modal-renegociacao"
      ref="modal-renegociacao"
      hide-footer
      centered
      size="lg"
      title="Nova Renegociação"
      no-close-on-backdrop
      no-close-on-esc
    >
      <validation-observer ref="formSimulacao">
        <gerar-antecipacao-exoneracao-obitos
          ref="renegociacaoRef"
          @closeModalCadastro="fecharModalCadastro"
          :parametro="dadosRenegociacao"
        />
      </validation-observer>
    </b-modal>
  </b-form>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { isNumber } from '@/libs/utils'
  import {
    formatarCodigoSequencial,
    formatarData,
    formatarInativoAposentado,
    formatarValor,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import naoEncontradoModal from '@/views/error/nao-encontrado-modal.vue'
  import { avatarText } from '@core/utils/filter'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import gerarAntecipacaoExoneracaoObitos from './gerarAntecipacaoExoneracaoObitos.vue'
  import RenegociacoesInformacoesAvancadas from './RenegociacoesInformacoesAvancadas.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      naoEncontradoModal,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
      gerarAntecipacaoExoneracaoObitos,
      RenegociacoesInformacoesAvancadas,
      formatarInativoAposentado,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    setup() {
      return {
        avatarText,
      }
    },
    data() {
      return {
        isNumber,
        formatarData,
        formatarCodigoSequencial,
        formatarInativoAposentado,
        formatarValor,
        number: {},
        selectedItems: [],
        somatoriaValores: 0,
        valorSelecionado: formatarValor(0),
        exibeCadastro: false,
        exibeBoleto: false,
        exibeSelecioneParcelas: false,
        sortByVisualizarParcelas: 'parcela',
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        sortDescVisualizarParcelas: false,
        sortDirectionVisualizarParcelas: 'asc',
        perPageVisualizarParcelas: 8,
        currentPageVisualizarParcelas: 1,
        fieldsVisualizarParcelas: [
          { key: 'parcela', sortable: true },
          { key: 'valor', sortable: true },
          { key: 'vencimento', sortable: true },
          { key: 'valorAntecipacao', label: 'Valor da Parcela Atualizada' },
        ],
        statusMap: [
          { key: 'Descontado', label: 'Parcela Descontada', variant: 'success' },
          { key: 'Rejeitada', label: 'Parcela Rejeitada', variant: 'danger' },
          { key: 'Vencida', label: 'Parcela Vencida', variant: 'danger' },
          { key: 'PagaParcialmente', label: 'Paga Parcialmente', variant: 'primary' },
          { key: 'NaoDescontada', label: 'Não Descontada', variant: 'secondary' },
          { key: 'Renegociada', label: 'Renegociada', variant: 'success' },
        ],
        totalRowsVisualizarParcelas: 0,
        itemsVisualizarParcelas: [],
        taxaShow: {},
        parametrosRenegociacao: {},
        primeiraParcela: {},
        parcelasAtualizadas: [],
        dadosRenegociacao: {},
        exibirGerarParcelas: false,
        dataVencimento: null,
      }
    },
    async mounted() {
      await this.carregarParametrosRenegociacao()

      this.$nextTick(async () => {
        // PENDENTES
        if (this.parametro.origem == 'exoneracaoPendentes' || this.parametro.origem == 'obitosPendentes') {
          this.exibeCadastro = true
          this.exibeBoleto = false
        }

        // REGULARIZADOS
        if (this.parametro.origem == 'ExoneracoesRegularizadas' || this.parametro.origem == 'obitosRegularizados') {
          this.exibeCadastro = false
          this.exibeBoleto = true
        }

        this.totalRowsVisualizarParcelas = this.parametro.parcela.length
      })
    },
    methods: {
      statusMapOptions(item, mode) {
        if (!item || !mode) return ''
        return this.statusMap.find((x) => x.key == item)[mode]
      },
      gerarRenegociacao() {
        this.dadosRenegociacao = {
          ...this.parametro,
          dadosParcelas: this.itemsVisualizarParcelas,
          dadosParcelaAtualizada: this.parcelasAtualizadas,
          parametrosRenegociacao: this.parametrosRenegociacao,
        }

        this.$refs['modal-renegociacao'].show()
      },
      async carregarParametrosRenegociacao() {
        useJwt
          .get(`renegociacao/GetParametrosRenegociacao/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.parametrosRenegociacao = response.data
            this.carregarParcelas()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      diferencaMeses(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em meses
        const mesesDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24 * 30.44)) // aproximadamente 30.44 dias em um mês

        return mesesDeDiferenca
      },
      fecharModalCadastro() {
        this.$refs['modal-renegociacao'].hide()
        this.$emit('closeModalVisualizar')
      },
      diferencaDias(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em dias
        const diasDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

        return diasDeDiferenca
      },
      async carregarParcelas() {
        this.itemsVisualizarParcelas = this.parametro.parcela
        let listaParcelaId = this.parametro.parcela.flatMap((x) => x.id)
        let parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.parametro.id,
          quantidadeDeParcelas: this.parametro.parcela.length,
          listaParcelasId: listaParcelaId,
        }

        await useJwt
          .post('renegociacao/GetCalcularParcelasAAntecipar', parametro)
          .then((response) => {
            if (response.data?.status == 'erro') {
              this.isBusy = false
              console.error(response.data.mensagem)
              this.$message.error('Erro ao calcular antecipações')
              return
            }
            let parcelasAntecipadas
            parcelasAntecipadas = response.data
            this.parcelasAtualizadas = parcelasAntecipadas.reduce((acc, item) => acc + Number(item.valorAntecipacao), 0)

            for (let parcela of this.itemsVisualizarParcelas) {
              let itemParcela = parcelasAntecipadas.find((x) => x.id == parcela.id)
              if (itemParcela) {
                parcela.valorAntecipacao = itemParcela.valorAntecipacao
              }
            }

            this.isBusy = false
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao calcular antecipações')
          })
      },
    },
  }
</script>

<style scoped>
  .left-aligned {
    float: left;
  }
</style>
