<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Pesquisa">
          <b-row>
            <b-col md="2">
              <b-form-group label="Pesquisar Por" label-for="opcao">
                <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input
                  id="descricao"
                  v-model="pesquisar.descricao"
                  placeholder="Descrição"
                  v-on:keydown.enter="carregarGrid"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 24px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-show="items.length > 0">
      <b-col class="w-100">
        <b-card>
          <b-table
            striped
            class="responsive"
            :per-page="pesquisar.perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isBusy"
            :current-page="pesquisar.currentPage"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>

            <template v-slot:[`cell(segurado.situacaoPrevidenciaria)`]="row">
              <b-badge pill variant="primary" class="mr-1">
                {{ String(row.item.segurado.situacaoPrevidenciaria).toUpperCase() }}
              </b-badge>
            </template>

            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>

                <b-dropdown-item @click="visualizarRenegociacao(row.item)">
                  <feather-icon icon="EyeIcon" size="16" />
                  <span class="align-middle ml-50">Visualizar Renegociação</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showVencimentoBoleto(row.item)">
                  <feather-icon icon="DollarSignIcon" size="16" />
                  <span class="align-middle ml-50">Gerar Boleto</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="row.item.statusRenegociacao != 'Pago'" @click="confirmarPagamento(row.item)">
                  <feather-icon icon="CheckIcon" size="16" />
                  <span class="align-middle ml-50">Confirmar Pagamento</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="cancelarRenegociacao(row.item)">
                  <feather-icon icon="XOctagonIcon" size="16" />
                  <span class="align-middle ml-50">Cancelar Renegociação</span>
                </b-dropdown-item> -->

                <b-dropdown-item
                  @click="aprovarRenegociacao(row.item.id)"
                  v-if="row.item.statusRenegociacao == 'Pendente'"
                  v-show="acesso.RenegociacaoAlterarStatus"
                >
                  <feather-icon icon="CheckSquareIcon" size="16" />
                  <span class="align-middle ml-50">Aprovar Renegociação</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="negarRenegociacao(row.item.id)"
                  v-if="row.item.statusRenegociacao == 'Pendente'"
                  v-show="acesso.RenegociacaoAlterarStatus"
                >
                  <feather-icon icon="XSquareIcon" size="16" />
                  <span class="align-middle ml-50">Negar Renegociação</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0 mt-1">
            <!-- page length -->
            <b-form-group
              style="align-items: center"
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
            </b-form-group>

            <!-- pagination -->
            <b-pagination
              v-model="pesquisar.currentPage"
              :total-rows="totalRows"
              :per-page="pesquisar.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <b-modal
      id="modal-vencimento-boleto"
      ref="modal-vencimento-boleto"
      centered
      title="Data de vencimento"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <validation-observer ref="boletoRules" tag="form">
        <validation-provider #default="{ errors }" name="Data de Vencimento" rules="required">
          <b-form-datepicker
            button-variant="primary"
            class="datePickerWithInput"
            v-model="dataVencimento"
            :min="new Date()"
            :hide-header="true"
            :initial-date="new Date()"
            label-help=""
            label-calendar="Calendário"
            label-close-button="Fechar"
            label-current-month="Mês atual"
            label-next-decade="Próxima década"
            label-next-month="Próximo mês"
            label-next-year="Próximo ano"
            label-prev-decade="Década anterior"
            label-prev-month="Mês anterior"
            label-prev-year="Ano anterior"
            label-select-month="Selecione o mês"
            label-select-year="Selecione o ano"
            label-selected="Selecionado"
            label-no-date-selected="Nenhuma data selecionada"
            label-reset-button="Resetar"
            label-today-button="Hoje"
            label-today="Hoje"
            locale="pt"
            :date-format-options="{ day: 'numeric', month: '2-digit', year: 'numeric' }"
            aria-controls="dataVencimento"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <b-button variant="primary" @click="gerarBoleto(itemBoleto)" class="mt-1" block>Gerar</b-button>
        </validation-provider>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-visualizar"
      ref="modal-visualizar"
      centered
      size="xl"
      title="Detalhes dessa Renegociação"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <modal-visualizar-exoneracao-obitos
        ref="simulacaoRef"
        @closeModalVisualizar="fecharModalRenegociacao"
        :parametro="visualizarSelecionada"
      ></modal-visualizar-exoneracao-obitos>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import renegociacaoDividaVisualizarModal from '@/views/pages/renegociacao/dividas/renegociacaoDivida/renegociacaoDividaVisualizarModal'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { formatarData, formatarValor } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import ModalVisualizarExoneracaoObitos from './modalVisualizarExoneracaoObitos.vue'
  import { geraBoleto, adicionaBoletoNaGeracaoDeRemessa, consultaBoletoGerado } from '@/utils/boleto/index'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      renegociacaoDividaVisualizarModal,
      AutenticaSenha,
      formatarData,
      ModalVisualizarExoneracaoObitos,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Matrícula' },
          { value: 3, text: 'Nº Contrato' },
        ],
        isBusy: false,
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'codigo', label: 'Nº Contrato' },
          {
            key: 'segurado.tomador.nomeCompleto',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'valorContratoAtualizado',
            label: 'Valor do Contrato',
            formatter: (value) => (value ? formatarValor(value) : ''),
          },
          { key: 'segurado.matricula', label: 'Matrícula' },
          {
            key: 'segurado.situacaoPrevidenciaria',
            label: 'Categoria',
          },
          {
            key: 'statusRenegociacao',
            label: 'Status',
          },
        ],
        parametroRenegociacao: {},
        parametroParcela: {},
        visualizarSelecionada: { tomador: {} },
        items: [],
        dados: {},
        acesso: {
          RenegociacaoConsultar: null,
          RenegociacaoCriarNovo: null,
          RenegociacaoAlterarStatus: null,
          RenegociacaoVisualizar: null,
        },
        grupoPermissao: {},
        dataVencimento: null,
        itemBoleto: null,
        valorContratoAtualizado: 0,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RenegociacaoConsultar = true
        this.acesso.RenegociacaoCriarNovo = true
        this.acesso.RenegociacaoAlterarStatus = true
        this.acesso.RenegociacaoVisualizar = true
      } else {
        this.acesso.RenegociacaoConsultar = this.userData.GrupoAcesso.RenegociacaoConsultar
        this.acesso.RenegociacaoCriarNovo = this.userData.GrupoAcesso.RenegociacaoCriarNovo
        this.acesso.RenegociacaoAlterarStatus = this.userData.GrupoAcesso.RenegociacaoAlterarStatus
        this.acesso.RenegociacaoVisualizar = this.userData.GrupoAcesso.RenegociacaoVisualizar
      }
    },
    methods: {
      pesquisarGrupo() {
        this.carregarGrid()
      },
      authEEdita(item) {
        this.items.item = item
        this.items.mode = 'authEEdita'
        this.$refs['modal-auth-senha'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEEdita') {
            this.editar(this.items.item)
          } else if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      async carregarGrid() {
        this.isBusy = true
        const institutoId = this.userData.institutoSelecionado
        let parametros = {
          ...this.pesquisar,
          institutoId,
        }

        useJwt
          .post(`renegociacao/GetPesquisarExoneradosRegularizados`, parametros)
          .then(async (response) => {
            if (response.data?.status == 'erro') {
              this.$message.error(response.data.mensagem)
              this.isBusy = false
              return
            }

            for (const item of response.data) {
              let valorContratoAtualizado = await this.carregaParcelasCalculadas(item)
              item.valorContratoAtualizado = valorContratoAtualizado
            }

            this.items = response.data
            this.totalRows = response.data.length
            this.isBusy = false

            if (this.items.length == 0) {
              this.$message.error('Não foram encontradas exonerações regularizadas!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fecharRenegociacao() {
        this.$refs['modal-renegociacao'].hide()
      },
      visualizarRenegociacao(item) {
        this.visualizarSelecionada = {
          ...item,
          origem: 'ExoneracoesRegularizadas',
        }
        this.$refs['modal-visualizar'].show()
      },
      async gerarBoleto(item) {
        this.$refs.boletoRules.validate().then(async (success) => {
          if (success) {
            this.$refs['modal-vencimento-boleto'].hide()
            this.$message.info('Iniciando a geração do boleto.')

            const institutoId = item.institutoId
            const tomadorId = item.segurado.tomador.id
            const renegociacaoId = item.parcela[0].RenegociacaoAntecipacao.id

            let rt = await adicionaBoletoNaGeracaoDeRemessa({
              institutoId: institutoId,
              renegociacaoAntecipacaoId: renegociacaoId,
              tomadorId: tomadorId,
              geradoPor: 'porAntecipacao',
              dataVencimento: this.dataVencimento,
            })

            if (rt.status != 200) {
              rt = await consultaBoletoGerado({
                institutoId: institutoId,
                renegociacaoAntecipacaoId: renegociacaoId,
                tomadorId: tomadorId,
                geradoPor: 'porAntecipacao',
                dataVencimento: this.dataVencimento,
              })
            }

            const valorBoleto = item.valorContratoAtualizado

            const obj = await geraBoleto(institutoId, tomadorId, {
              valor: valorBoleto,
              dataVencimento: this.dataVencimento,
              nossoNumero: `${rt.data.id}`,
              numeroDocumento: `${rt.data.id}`,
            })

            const resp = await useJwt.post('boleto/geraBoleto', obj)

            if (resp.data.status != 200) {
              this.$message.error('Erro ao gerar boleto')
              this.dataVencimento = null
              return
            }

            this.$message.success('Boleto gerado com sucesso')
            this.dataVencimento = null
            window.open(resp.data.response, resp.data.response)
          }
        })
      },
      async confirmarPagamento(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente confirmar o pagamento da renegociação?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            let parametros = {
              ...item,
            }
            this.isBusy = true

            await useJwt
              .post(`renegociacao/ConfirmarPagamentoExonenacoesRegularizadas`, parametros)
              .then(() => {
                this.isBusy = false
                this.$message.success('Pagamento confirmado com sucesso!')
                this.carregarGrid()
              })
              .catch((error) => {
                this.isBusy = false
                console.error(error)
              })
          }
        })
      },
      cancelarRenegociacao(item) {
        this.$message.info('Em desenvolvimento ...')
      },
      aprovarRenegociacao() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      negarRenegociacao(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente reprovar renegociação selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/instituto/negarRenegociacao`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      editar(item) {
        useJwt
          .get(`grupo-acesso/editarGrupo/${item}`)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fecharModalRenegociacao() {
        this.$refs['modal-visualizar'].hide()
        this.carregarGrid()
      },
      permissoes(item) {
        // useJwt
        //   .get(`grupo-acesso/editarGrupoPermissoes/${item}`)
        //   .then((response) => {
        //     this.grupoPermissao = response.data;
        //     this.$refs["modal-workflow-modal"].show();
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
      },
      showVencimentoBoleto(item) {
        this.itemBoleto = item
        this.$refs['modal-vencimento-boleto'].show()
      },
      async carregaParcelasCalculadas(item) {
        let listaParcelaId = item.parcela.flatMap((x) => x.id)
        let parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: item.id,
          quantidadeDeParcelas: item.parcela.length,
          listaParcelasId: listaParcelaId,
        }

        let valorContratoAtualizado
        await useJwt
          .post('renegociacao/GetCalcularParcelasAAntecipar', parametro)
          .then((response) => {
            valorContratoAtualizado = response.data.reduce((acc, item) => acc + Number(item.valorAntecipacao), 0)
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao calcular antecipações')
          })

        return valorContratoAtualizado
      },
    },
  }
</script>
