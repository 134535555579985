<template>
  <div>
    <b-tabs pills>
      <b-tab title="Exonerações" active>
        <b-card-text>
          <exoneracoes-pendentes />
        </b-card-text>
      </b-tab>

      <b-tab title="Óbitos">
        <b-card-text>
          <obitos-pendentes />
        </b-card-text>
      </b-tab>

      <b-tab title="Parcelas Vencidas">
        <b-card-text>
          <parcelas-vencidas-pendentes />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ExoneracoesPendentes from './exoneracoesPendentes.vue'
  import ObitosPendentes from './obitosPendentes.vue'
  import ParcelasVencidasPendentes from './parcelasVencidasPendentes.vue'

  export default {
    components: {
      ExoneracoesPendentes,
      ObitosPendentes,
      ParcelasVencidasPendentes,
    },
  }
</script>
