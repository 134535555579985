<template>
  <form-wizard
    shape="circle"
    color="#016b3a"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvar"
  >
    <!-- Segurado -->
    <tab-content title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="SegRules" tag="form">
        <!-- Consulta Segurado -->
        <b-form-group label="Segurado" label-for="segurado" v-show="campoSeguradoSelecionado">
          <validation-provider #default="{ errors }" name="Segurado" rules="required">
            <vue-autosuggest
              id="segurado"
              v-model="renegociacao.segurado"
              :suggestions="suggestions"
              :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: 'Digite aqui o nome do tomador' }"
              :get-suggestion-value="getSuggestionValue"
              @selected="campoSeguradoSelecao"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div class="d-flex align-items-center">
                  <b-avatar :src="suggestion.item.segurado.tomador.foto ? suggestion.item.segurado.tomador.foto.foto : null" />
                  <div class="detail ml-50">
                    <b-card-text class="mb-0">
                      {{ suggestion.item.segurado.tomador.nomeCompleto }}
                    </b-card-text>
                    <small class="text-muted">
                      CPF: {{ suggestion.item.segurado.tomador.cpf }} - Categoria:
                      {{ formatarInativoAposentado(suggestion.item.segurado.situacaoPrevidenciaria) }} - Matrícula:
                      {{ suggestion.item.segurado.matricula }} -
                      <b-badge pill variant="primary"> Contrato: {{ suggestion.item.codigo }} </b-badge>
                    </small>
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Campos do Segurado -->
        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-form-input readonly id="cpf" type="text" placeholder="CPF" v-model="segurado.tomador.cpf" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-form-input
                readonly
                id="nomeCompleto"
                type="text"
                placeholder="Nome Completo"
                v-model="segurado.tomador.nomeCompleto"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-form-input
                readonly
                id="categoria"
                type="text"
                placeholder="Categoria"
                :value="formatarInativoAposentado(segurado.categoria)"
              />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-form-input
                readonly
                id="nomeSocial"
                type="text"
                placeholder="Nome Social"
                v-model="segurado.tomador.nomeSocial"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-form-input
                readonly
                id="dataNascimento"
                type="text"
                placeholder="Data de Nascimento"
                v-model="segurado.tomador.dataNascimento"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-form-input
                readonly
                id="remuneracaoProventos"
                type="text"
                placeholder="Remuneração"
                v-model="segurado.remuneracaoProventos"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Disponível" label-for="margemDisponivel">
              <b-form-input
                readonly
                id="margemDisponivel"
                type="text"
                placeholder="Margem Disponível"
                v-model="segurado.margemDisponivel"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Nº do Contrato" label-for="numeroContrato">
              <b-form-input
                readonly
                id="numeroContrato"
                type="text"
                placeholder="Número do Contrato"
                v-model="numeroContrato"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tipo de Acordo  -->
    <tab-content title="Tipo de Acordo" :before-change="validationTipoCalculo">
      <validation-observer ref="tipRules" tag="form" rules="required">
        <b-row>
          <b-col md="12">
            <b-form-group class="pb-2" label="Tipo de Pagamento" v-slot="{ ariaDescribedby }">
              <validation-provider #default="{ errors }" name="Tipo de Pagamento" rules="required">
                <b-form-radio-group
                  id="radiosTipoDeCalculo"
                  v-model="renegociacao.tipoCalculo"
                  :options="optionsTipoPagamento"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                ></b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tabela de Parcelas Pendentes -->
    <tab-content title="Tabela de Parcelas" :before-change="validationTabelaParcelas">
      <validation-observer ref="tabelaParcelasRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              v-if="calculoOk"
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="dadosGrid"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
            >
              <template #foot(check)>
                <span>Total: </span>
              </template>

              <template #foot(parcelaAtual)>
                {{ '' }}
              </template>

              <template #foot(valor)>
                <span>{{ valorTotalParcelas ? formatarValor(valorTotalParcelas) : formatarValor(0.0) }}</span>
              </template>

              <template #foot(valorAtualizado)>
                <span>{{
                  valorTotalParcelasAtualizadas ? formatarValor(valorTotalParcelasAtualizadas) : formatarValor(0.0)
                }}</span>
              </template>

              <template #foot(vencimento)>
                {{ '' }}
              </template>

              <template #foot(status)>
                {{ '' }}
              </template>

              <template #cell(check)="row">
                <b-form-checkbox
                  v-model="selectedItems"
                  @change="adicionaContadorSelecionado()"
                  :value="row.item.id"
                  :name="'row-' + row.index"
                ></b-form-checkbox>
              </template>

              <template #cell(status)="row">
                <template
                  v-if="
                    statusMap
                      ? statusMap[row.item.statusDesconto]
                        ? statusMap[row.item.statusDesconto].length > 1
                        : false
                      : false
                  "
                >
                  <b-badge :variant="statusMap[row.item.statusDesconto][1]">
                    {{ statusMap[row.item.statusDesconto][0] }}
                  </b-badge>
                </template>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Dados da consignação:  -->
    <tab-content title="Dados da Renegociação" :before-change="validationDadosConsignacao">
      <validation-observer ref="dadosRules" tag="form">
        <b-row>
          <b-col md="4">
            <b-form-group label="Valor da Renegociação" label-for="valorRenegociacao">
              <b-form-input
                :disabled="true"
                id="valorRenegociacao"
                placeholder="Valor da Renegociação"
                :value="formatarValor(valorTotalSelecionado)"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Quantidade de Parcelas" label-for="quantidadeParcelas">
              <b-form-input
                :disabled="true"
                id="quantidadeParcelas"
                placeholder="Quantidade de Parcelas"
                :value="selectedItems.length"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Tipo de Pagamento" label-for="tipoPagamento">
              <b-form-input
                :disabled="true"
                id="tipoPagamento"
                placeholder="Tipo de Pagamento"
                :value="
                  renegociacao.tipoCalculo
                    ? optionsTipoPagamento.find((item) => item.value == renegociacao.tipoCalculo).text
                    : ''
                "
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { detectarNavegador, isNumber } from '@/libs/utils'
  import {
    formatarData,
    formatarInativoAposentado,
    formatarParaNumber,
    formatarValor,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { VueAutosuggest } from 'vue-autosuggest'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        loading: false,
        calculoOk: false,
        isNumber,
        formatarValor,
        formatarInativoAposentado,
        number: {},
        renegociacao: { status: 0 },
        segurado: { tomador: {} },
        suggestions: [],
        timeout: null,
        debounceMilliseconds: 300,
        numeroContrato: '',
        required,
        valor: 0,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        valorTotalSelecionado: 0,
        valorTotalParcelasAtualizadas: 0,

        fieldsParcelas: [
          { key: 'check', label: 'Selecionar' },
          { key: 'parcelaAtual', label: 'Parcela' },
          { key: 'valor', label: 'Valor', formatter: (value) => formatarValor(value) },
          { key: 'valorAtualizado', label: 'Valor Atualizado', formatter: (value) => formatarValor(value) },
          { key: 'vencimento', label: 'Vencimento', formatter: (value) => formatarData(value) },
          { key: 'status', label: 'Status' },
        ],
        itemsParcelas: [],
        dadosGrid: [],
        parametrosRenegociacao: null,
        totalRowsParcelas: 0,
        valorTotalParcelas: 0,
        selectedItems: [],
        statusMap: {
          Descontado: ['Parcela Descontada', 'success'],
          Rejeitada: ['Parcela Rejeitada', 'danger'],
          PagaParcialmente: ['Paga Parcialmente', 'warning'],
          NaoDescontada: ['Não Descontada', 'secondary'],
          Renegociada: ['Renegociada', 'info'],
          Vencida: ['Vencida', 'danger'],
        },

        exibeDadosSegurado: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        optionsTipoPagamento: [
          { text: 'Desconto em folha', value: 'descontoEmFolha' },
          { text: 'Boleto Bancário', value: 'boletoBancario' },
        ],
        optionsTipoRenegociacao: [{ text: 'Parcelas em Atraso', value: 'dividasEmAtraso' }],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Aprovado', text: 'Aprovado' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Negado', text: 'Negado' },
        ],

        campoSeguradoSelecionado: true,
      }
    },
    mounted() {
      this.renegociacao = {
        ...this.renegociacao,
        institutoSelecionado: this.userData.institutoSelecionado,
        institutoId: this.userData.institutoSelecionado,
      }

      this.renegociacao.formaCalculo = 'dividasEmAtraso'
      this.exibeDadosSegurado = false
      this.renegociacao.tipoPagamento = 'descontoEmFolha'
      this.renegociacao.tipoRenegociacao = 'amortizarParcelas'
    },
    methods: {
      fetchResults() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.renegociacao.segurado) {
            this.renegociacao = {
              ...this.renegociacao,
              seguradoSelecionado: null,
              segurado: null,
            }
            return
          }

          if (this.renegociacao.segurado.length)
            useJwt
              .pesquisar(`renegociacao/GetContratosComParcelasVencidas`, this.renegociacao)
              .then((response) => {
                if (response.data.length > 0) {
                  this.isBusy = false
                  this.suggestions = [{ data: response.data }]
                  this.totalRows = response.data?.lenght ?? 0
                } else {
                  this.isBusy = false
                  this.$message.error('Nenhum registro foi encontrado!')
                }
              })
              .catch((error) => {
                console.error(error)
              })
          else this.suggestions = []
        }, this.debounceMilliseconds)
      },
      campoSeguradoSelecao() {
        this.campoSeguradoSelecionado = false
      },
      getSuggestionValue(suggestion) {
        const { item } = suggestion

        this.exibeDadosSegurado = true
        this.segurado.id = item.id
        this.segurado.tomador.cpf = item.segurado.tomador.cpf
        this.segurado.tomador.nomeCompleto = item.segurado.tomador.nomeCompleto
        this.segurado.tomador.nomeSocial = item.segurado.tomador.nomeSocial
        this.segurado.tomador.dataNascimento = formatarData(item.segurado.tomador.dataNascimento)
        this.segurado.categoria = item.segurado.situacaoPrevidenciaria
        this.segurado.remuneracaoProventos = formatarValor(item.segurado.remuneracao)
        this.segurado.margemUtilizada = formatarValor(item.segurado.margemUtilizada)
        this.segurado.margemDisponivel = formatarValor(item.segurado.margemDisponivel)
        this.renegociacao.seguradoSelecionado = item.segurado
        this.renegociacao.contratoSelecionado = item
        this.numeroContrato = item.codigo

        return item.nomeCompleto
      },
      validationDadosSegurado() {
        return new Promise((resolve, reject) => {
          this.$refs.SegRules.validate().then((success) => {
            if (success && this.exibeDadosSegurado) {
              resolve(true)
            } else {
              let title = ''
              if (!this.exibeDadosSegurado) title = 'Atenção: É necessário selecionar um segurado!'
              else title = 'Atenção: Há campos não preenchidos no formulário.'
              this.msgError(title)
              reject()
            }
          })
        })
      },
      validationTipoCalculo() {
        return new Promise((resolve, reject) => {
          this.$refs.tipRules.validate().then((success) => {
            if (success) {
              this.gerarParcelas()
              resolve(true)
            } else {
              let title = 'Erro: Há campos não preenchidos no formulário.'
              this.msgError(title)
              reject()
            }
          })
        })
      },
      validationDadosConsignacao() {
        return new Promise((resolve, reject) => {
          this.$refs.dadosRules.validate().then((success) => {
            if (success) {
              resolve(true)
            } else {
              let title = 'Erro: Há campos não preenchidos no formulário.'
              this.msgError(title)
              reject()
            }
          })
        })
      },
      validationTabelaParcelas() {
        return new Promise((resolve, reject) => {
          this.$refs.tabelaParcelasRules.validate().then((success) => {
            if (!this.selectedItems.length) {
              this.$message.error('Selecione pelo menos uma parcela para renegociar!')
              reject(false)
            }
            if (this.renegociacao.tipoCalculo == 'descontoEmFolha') {
              let parcela = this.dadosGrid.find((item) => item.id == this.selectedItems[0])
              if (parcela?.valorAtualizado > formatarParaNumber(this.segurado.margemDisponivel)) {
                this.$message.error(
                  'O valor da parcela não pode ser superior a margem quando o tipo de pagamento for Desconto em Folha!',
                )
                reject(false)
              }
            }

            if (success) {
              resolve(true)
            }
          })
        })
      },
      gerarParcelas() {
        let parametros = {
          financeiroId: this.renegociacao.contratoSelecionado.id,
          institutoId: this.userData.institutoSelecionado,
        }
        this.loading = true

        useJwt
          .post('renegociacao/GetParcelasSeguradoRenegociacao', parametros)
          .then((response) => {
            // Verificar necessidade
            if (response.data.status === 'atencao') {
              this.$swal({
                title: 'Atenção!',
                text: response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                this.loading = false
              })
              return
            }

            this.itemsParcelas = response.data
            this.valorTotalParcelas = this.itemsParcelas.reduce(
              (accumulator, currentValue) => Number(accumulator) + Number(currentValue.valor),
              0,
            )
            this.totalRowsParcelas = response.data.length

            useJwt
              .post('renegociacao/GetParametros', { institutoId: this.userData.institutoSelecionado })
              .then(async (response) => {
                this.parametrosRenegociacao = response.data
                await this.adicionaJurosEMultas(this.parametrosRenegociacao)
                this.valorTotalParcelasAtualizadas = this.itemsParcelas.reduce(
                  (accumulator, currentValue) => Number(accumulator) + Number(currentValue.valorAtualizado),
                  0,
                )
              })
              .catch((error) => {
                console.error(error)
                this.$message.error('Erro ao buscar parâmetros de renegociação')
                this.loading = false
              })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao buscar parcelas para renegociação')
            this.loading = false
          })
      },
      adicionaJurosEMultas(parametrosRenegociacao) {
        return new Promise((resolve, reject) => {
          try {
            this.itemsParcelas.forEach((parcela) => {
              const dataVencimento = new Date()
              const dataMultaApos = parametrosRenegociacao.parcelasEmAtrasoParaDivida
              dataVencimento.setMonth(dataVencimento.getMonth() - dataMultaApos)

              if (parcela.statusDesconto != 'Descontado') {
                if (new Date(parcela.vencimento) < dataVencimento && parcela.statusDesconto != 'Renegociada') {
                  parcela.statusDesconto = 'Vencida'
                }
              }
            })

            this.dadosGrid = this.itemsParcelas

            this.loading = false
            this.calculoOk = true
            resolve(true)
          } catch (error) {
            reject('Erro: ' + error)
          }
        })
      },
      diferencaMeses(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em meses
        const mesesDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24 * 30.44)) // aproximadamente 30.44 dias em um mês

        return mesesDeDiferenca
      },
      diferencaDias(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em dias
        const diasDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

        return diasDeDiferenca
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      msgSuccess(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'UserCheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      async salvar() {
        const renegociacao = this.renegociacao
        let navegadorUtilizado = detectarNavegador()

        if (!renegociacao.status) {
          let percentualJuros = this.parametrosRenegociacao.renegociacaoJurosAposVencimento
          let percentualMulta = this.parametrosRenegociacao.renegociacaoMulta

          let parcelasSelecionadas = []
          this.selectedItems.forEach((item) => {
            let parcela = this.itemsParcelas.find((parcela) => parcela.id == item)
            parcelasSelecionadas.push(parcela)
          })

          let dataToSave = {
            taxaDeJurosParametro: percentualJuros,
            taxaDeMultaParametro: percentualMulta,
            navegadorUtilizado: navegadorUtilizado,
            valorRenegociacao: this.valorTotalSelecionado,
            valorContrato: formatarParaNumber(this.renegociacao.contratoSelecionado.valorContrato),
            parcelas: parcelasSelecionadas,
            institutoId: renegociacao.institutoSelecionado,
          }

          let endpoint = 'renegociacao/novoAcordo'
          const { segurado, seguradoSelecionado, ...data } = { ...dataToSave }

          useJwt
            .post(endpoint, data)
            .then((response) => {
              if (response.data.status == 'erro') {
                this.$swal({
                  title: 'Atenção!',
                  text: response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    this.loading = !this.loading
                    this.$emit('atualizarGrid')
                    this.$emit('closeSimulationModal')
                  }
                })
                return
              }
              this.$swal({
                title: 'Atenção!',
                text: 'Renegociação gerada com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  this.loading = !this.loading
                  this.$emit('atualizarGrid')
                  this.$emit('closeSimulationModal')
                }
              })
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        } else {
          this.loading = false
          this.$swal({
            title: 'Atenção!',
            text: renegociacao.error,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      },
      adicionaContadorSelecionado() {
        this.valorTotalSelecionado = 0
        this.selectedItems.forEach((item) => {
          let parcela = this.itemsParcelas.find((parcela) => parcela.id == item)
          this.valorTotalSelecionado += formatarParaNumber(parcela?.valorAtualizado)
        })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  pre {
    min-height: 295px;
    padding: 1.5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }

  .autosuggest__results-item {
    cursor: pointer;
  }

  #radiosFormasDeCalculo,
  #radiosTipoDeCalculo {
    display: flex;
  }

  #radiosFormasDeCalculo label span {
    white-space: nowrap;
  }
</style>
