<template>
  <b-form>
    <b-row>
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="parametro.foto != null ? parametro.foto.foto : null"
            :text="avatarText(parametro.segurado.tomador.nomeCompleto)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ parametro.segurado.tomador.nomeCompleto }}
              </h4>
              <span class="card-text">CPF: {{ parametro.segurado.tomador.cpf }}</span
              ><br />
              <span class="card-text"
                >Renegociação:
                <span class="badge badge-light-primary badge-pill">{{
                  this.formatarCodigoSequencial(parametro.codigo)
                }}</span></span
              >
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100" aria-describedby="gridRelatorio">
          <tr>
            <th class="pb-50">
              <feather-icon icon="BriefcaseIcon" class="mr-75" />
              <span class="font-weight-bold">Órgão</span>
            </th>
            <td class="pb-50">
              {{ parametro.segurado.orgao ? parametro.segurado.orgao.nome : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Categoria</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ parametro.segurado.situacaoPrevidenciaria }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefone</span>
            </th>
            <td>
              {{ parametro.segurado.tomador.telefone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="AtSignIcon" class="mr-75" />
              <span class="font-weight-bold">E-mail</span>
            </th>
            <td>
              {{ parametro.segurado.tomador.email }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="3">
        <b-form-group label="Valor do Contrato Atualizado" label-for="valor">
          <b-form-input
            id="valor"
            placeholder="Valor da Proposta"
            :value="formatarValor(parcelasAtualizadas)"
            :readonly="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Parcelas" label-for="parcelas">
          <b-form-input id="parcelas" placeholder="Parcelas" :value="parametro.numeroParcelas" :readonly="true" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Data 1º Parcela" label-for="primeiraParcela">
          <b-form-input
            id="primeiraParcela"
            class="form-control"
            type="text"
            placeholder="DD/MM/AAAA"
            :value="formatarData(primeiraParcela.vencimento)"
            :readonly="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor 1º Parcela" label-for="valorPrimeiraParcela">
          <b-form-input
            id="valorPrimeiraParcela"
            placeholder="Valor 1º Parcela"
            :value="formatarValor(primeiraParcela.valor)"
            :readonly="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label="Taxa %" label-for="taxa">
          <b-form-input id="taxa" placeholder="Taxa %" :value="parametro.taxas" :readonly="true" />
        </b-form-group>
      </b-col>

      <b-col md="3" v-if="exibirGerarParcelas">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="margin-top: 21px; width: 188px"
          @click="gerarRenegociacao()"
        >
          Gerar Renegociação
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-table
          striped
          responsive
          class="position-relative"
          :per-page="perPageVisualizarParcelas"
          :current-page="currentPageVisualizarParcelas"
          :items="itemsVisualizarParcelas"
          :fields="fieldsVisualizarParcelas"
          :sort-by.sync="sortByVisualizarParcelas"
          :sort-desc.sync="sortDescVisualizarParcelas"
          :sort-direction="sortDirectionVisualizarParcelas"
        >
          <template #cell(valor)="row">
            {{ formatarValor(row.item.valor) }}
          </template>
          <template #cell(valorAtualizado)="row">
            {{ formatarValor(row.item.valorAtualizado ? row.item.valorAtualizado : '') }}
          </template>
          <template #cell(vencimento)="row">
            {{ formatarData(row.item.vencimento) }}
          </template>
          <template #cell(statusDesconto)="row">
            <!-- {{ row.item.statusDesconto }} -->
            <b-badge :variant="statusMapOptions(row.item.statusDesconto, 'variant')">
              {{ statusMapOptions(row.item.statusDesconto, 'label') }}
            </b-badge>
          </template>
        </b-table>

        <b-col md="12" v-if="itemsVisualizarParcelas.length == 0">
          <naoEncontradoModal />
        </b-col>

        <b-card-body class="d-flex justify-content-center flex-wrap pt-0" v-if="itemsVisualizarParcelas.length != 0">
          <b-pagination
            v-model="currentPageVisualizarParcelas"
            :total-rows="totalRowsVisualizarParcelas"
            :per-page="perPageVisualizarParcelas"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>
    <!-- Modal Renegociação -->
    <b-modal
      id="modal-renegociacao"
      ref="modal-renegociacao"
      hide-footer
      centered
      size="lg"
      title="Nova Renegociação"
      no-close-on-backdrop
      no-close-on-esc
    >
      <validation-observer ref="formSimulacao">
        <gerar-antecipacao-exoneracao-obitos ref="renegociacaoRef" :parametro="dadosRenegociacao" />
      </validation-observer>
    </b-modal>
  </b-form>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { isNumber, formatarValor, formatarData, formatarCodigoSequencial } from '@/libs/utils'
  import { avatarText } from '@core/utils/filter'
  import naoEncontradoModal from '@/views/error/nao-encontrado-modal.vue'
  import gerarAntecipacaoExoneracaoObitos from '../components/gerarAntecipacaoExoneracaoObitos.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      naoEncontradoModal,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
      gerarAntecipacaoExoneracaoObitos,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    setup() {
      return {
        avatarText,
      }
    },
    data() {
      return {
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        number: {},
        sortByVisualizarParcelas: 'parcela',
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        sortDescVisualizarParcelas: false,
        sortDirectionVisualizarParcelas: 'asc',
        perPageVisualizarParcelas: 8,
        currentPageVisualizarParcelas: 1,
        fieldsVisualizarParcelas: [
          { key: 'parcela', sortable: true },
          { key: 'valor', sortable: true },
          { key: 'vencimento', sortable: true },
          { key: 'valorAtualizado', label: 'Valor da Parcela Atualizada' },
          { key: 'statusDesconto', label: 'Status' },
        ],
        statusMap: [
          { key: 'Descontado', label: 'Parcela Descontada', variant: 'success' },
          { key: 'Rejeitada', label: 'Parcela Rejeitada', variant: 'danger' },
          { key: 'Vencida', label: 'Parcela Vencida', variant: 'danger' },
          { key: 'PagaParcialmente', label: 'Paga Parcialmente', variant: 'primary' },
          { key: 'NaoDescontada', label: 'Não Descontada', variant: 'secondary' },
          { key: 'Renegociada', label: 'Renegociada', variant: 'success' },
        ],
        totalRowsVisualizarParcelas: 0,
        itemsVisualizarParcelas: [],
        taxaShow: {},
        parametrosRenegociacao: {},
        primeiraParcela: {},
        parcelasAtualizadas: [],
        dadosRenegociacao: {},
        exibirGerarParcelas: false,
      }
    },
    async mounted() {
      await this.carregarParametrosRenegociacao()
    },
    methods: {
      statusMapOptions(item, mode) {
        if (!item || !mode) return ''
        return this.statusMap.find((x) => x.key == item)[mode]
      },
      async carregarParcelas() {
        const dataAtual = new Date()
        const dataVencimento = new Date(dataAtual)
        const dataMultaApos = await this.parametrosRenegociacao.parcelasEmAtrasoParaDivida
        let valorAtualizado = []
        // Subtrair 5 meses da data atual
        dataVencimento.setMonth(dataVencimento.getMonth() - dataMultaApos)

        this.itemsVisualizarParcelas = this.parametro.parcela

        if (this.itemsVisualizarParcelas) {
          let somaTotal = 0
          this.itemsVisualizarParcelas.forEach((parcela) => {
            if (parcela.statusDesconto != 'Descontado') {
              if (
                new Date(parcela.vencimento) < dataVencimento &&
                !(parcela.statusDesconto == 'Descontado' || parcela.statusDesconto == 'Renegociada')
              ) {
                parcela.statusDesconto = 'Vencida'
                this.exibirGerarParcelas = true

                let percentualJuros = Number(this.parametrosRenegociacao.instituto.renegociacaoJurosAposVencimento) / 100
                let percentualMulta = Number(this.parametrosRenegociacao.instituto.renegociacaoMulta) / 100
                let multaAposQuantosMeses = Number(this.parametrosRenegociacao.instituto.renegociacaoMultaApos)
                let tipoJuros = this.parametrosRenegociacao.instituto.renegociacaoTipoJuros
                let entrouMulta = false

                // NOTE: Primeiro valida a multa
                let valorMulta = 0

                if (this.diferencaMeses(parcela.vencimento) >= multaAposQuantosMeses) {
                  valorMulta = parcela.valor * percentualMulta
                }

                // NOTE: Depois valida o juros
                let valorJuros = 0

                if (tipoJuros == 'aoMesCalculoDiario') {
                  let quantidadeDeDias = this.diferencaDias(parcela.vencimento)
                  valorJuros = parcela.valor * (percentualJuros * quantidadeDeDias)
                } else if (tipoJuros == 'aoMesNaoCumulativo') {
                  let quantidadeDeMeses = this.diferencaMeses(parcela.vencimento)
                  valorJuros = parcela.valor * (percentualJuros * quantidadeDeMeses)
                }

                if (parcela.parcela == '1') {
                  this.primeiraParcela = parcela
                }
                parcela.valorAtualizado = Number(parcela.valor) + valorJuros + valorMulta
              } else {
                parcela.valorAtualizado = Number(parcela.valor)
                if (parcela.parcela == '1') {
                  this.primeiraParcela = parcela
                }
              }
            }
          })
          this.itemsVisualizarParcelas.forEach((parcelas) => {
            if (parcelas.valorAtualizado) {
              somaTotal += parcelas.valorAtualizado
            }
          })
          this.parcelasAtualizadas = somaTotal
        }

        this.totalRowsVisualizarParcelas = this.itemsVisualizarParcelas.length
      },
      gerarRenegociacao() {
        this.dadosRenegociacao = {
          ...this.parametro,
          dadosParcelas: this.itemsVisualizarParcelas,
          dadosParcelaAtualizada: this.parcelasAtualizadas,
          parametrosRenegociacao: this.parametrosRenegociacao,
        }
        this.$refs['modal-renegociacao'].show()
      },
      async carregarParametrosRenegociacao() {
        useJwt
          .get(`renegociacao/GetParametrosRenegociacao/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.parametrosRenegociacao = response.data
            this.carregarParcelas()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      diferencaMeses(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em meses
        const mesesDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24 * 30.44)) // aproximadamente 30.44 dias em um mês

        return mesesDeDiferenca
      },
      diferencaDias(dataPassada) {
        // Crie objetos de data para a data passada e a data atual
        const dataAtual = new Date()
        const dataPassadaObj = new Date(dataPassada)

        // Verifique se a data passada é realmente uma data válida
        if (isNaN(dataPassadaObj)) {
          return 'Data passada inválida.'
        }

        // Calcule a diferença em milissegundos entre as duas datas
        const diferencaEmMilissegundos = dataAtual - dataPassadaObj

        // Converta a diferença em dias
        const diasDeDiferenca = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

        return diasDeDiferenca
      },
    },
  }
</script>
