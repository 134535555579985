<template>
  <form-wizard
    shape="circle"
    color="#016b3a"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvarRenegociacao"
  >
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
          Voltar
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <b-row>
          <b-col md="6" v-if="props.activeTabIndex == 1 && parametro.origem == 'exoneracaoPendentes'">
            <b-button variant="primary" @click="calcular">Calcular</b-button>
          </b-col>
          <b-col :md="props.activeTabIndex == 1 ? 6 : 12" v-if="!props.isLastStep">
            <wizard-button
              :disabled="!habilitarBotaoProximoCalcular(props.activeTabIndex)"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              >Próximo
            </wizard-button>
          </b-col>
          <b-col :md="props.activeTabIndex == 1 ? 6 : 12" v-else>
            <b-button
              variant="primary"
              @click="salvarRenegociacao"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
            >
              {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}</b-button
            >
          </b-col>
        </b-row>
      </div>
    </template>

    <tab-content title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="tomRules" tag="form">
        <b-row>
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input v-else readonly id="cpf" type="text" placeholder="CPF" v-model="segurado.tomador.cpf" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="nomeCompleto"
                type="text"
                placeholder="Nome Completo"
                v-model="segurado.tomador.nomeCompleto"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input v-else readonly id="categoria" type="text" placeholder="Categoria" v-model="segurado.categoria" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="nomeSocial"
                type="text"
                placeholder="Nome Social"
                v-model="segurado.tomador.nomeSocial"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="dataNascimento"
                type="text"
                placeholder="Data de Nascimento"
                v-model="segurado.tomador.dataNascimento"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="remuneracaoProventos"
                type="text"
                placeholder="Remuneração"
                v-model="segurado.remuneracaoProventos"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Utilizada" label-for="margemUtilizada">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="margemUtilizada"
                type="text"
                placeholder="Margem Utilizada"
                v-model="segurado.margemUtilizada"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Disponível" label-for="margemDisponivel">
              <b-skeleton v-if="carregandoDadosIniciais" type="input"></b-skeleton>
              <b-form-input
                v-else
                readonly
                id="margemDisponivel"
                type="text"
                placeholder="Margem Disponível"
                v-model="segurado.margemDisponivel"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Dados da consignação:  -->
    <tab-content title="Dados da renegociação" :before-change="validationDadosConsignacao">
      <validation-observer ref="dadosRules" tag="form">
        <b-row>
          <b-col md="4" v-if="habilitaCampoValorRescisao">
            <b-form-group label="Valor da Rescisão" label-for="valorRescisao">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorRescisao"
                  placeholder="Valor da Rescisão"
                  v-model="proposta.valorRescisao"
                  v-number="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Valor da Renegociação" label-for="valor">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  :disabled="true"
                  style="padding-left: 5px; padding-right: 0px"
                  id="valor"
                  placeholder="Valor da Renegociação"
                  v-model="valorRenegociacao"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Parcelas" label-for="parcelas">
              <validation-provider #default="{ errors }" name="Parcelas" :rules="regrasValidacaoParcelas">
                <b-form-input
                  id="parcelas"
                  :disabled="true"
                  placeholder="Parcelas"
                  v-model="proposta.parcelas"
                  v-on:keypress="isNumber($event)"
                  maxlength="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tabela de Parcelas  -->
    <tab-content title="Tabela de Parcelas" :before-change="validationTabelaParcelas">
      <validation-observer ref="bankRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="itemsParcelas"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
              :busy="isBusy"
            >
              <template #cell(valorAntecipacao)="row">
                {{ formatarValor(row.item.valorAntecipacao) }}
              </template>

              <template #cell(valorPago)="row">
                {{
                  row.item.valorAntecipacao - row.item.valorPago != 0
                    ? formatarValor(row.item.valorPago) + ' (Parcial)'
                    : formatarValor(row.item.valorPago)
                }}
              </template>

              <template #foot(parcela)>
                <span>Total: </span>
              </template>

              <template #foot(valorPago)>{{ '' }}</template>

              <template #foot(valorAntecipacao)>
                <span>{{ valorTotalParcelas ? valorTotalParcelas : 0.0 }}</span>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { parcela, required, valor } from '@validations'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { VueAutosuggest } from 'vue-autosuggest'
  import {
    isNumber,
    formatarValor,
    formatarData,
    formatarParseFloat,
    formatarToLocaleString,
    formatarDataWTime,
    formatarParaNumber,
    detectarNavegador,
  } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
      WizardButton,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        type: Object,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        carregandoDadosIniciais: false,
        cadastroFinalizado: false,
        isBusy: false,
        loading: false,
        isNumber,
        formatarValor,
        formatarData,
        number: {},
        regrasValidacaoParcelas: {},
        habilitaCampoValorRescisao: false,
        proposta: { status: 0, parcelas: 0 },
        segurado: { tomador: {} },
        suggestions: [],
        timeout: null,
        debounceMilliseconds: 300,
        required,
        valor: 0,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: [
          { key: 'numeroParcela', label: 'Parcela' },
          { key: 'valorAntecipacao', label: 'Valor da Antecipação' },
        ],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        valorTotalParcelas: 0,
        exibeDadosSegurado: false,
        vinculoOpcoesCalculo: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        optionsTipoPagamento: [{ text: 'Boleto Bancário', value: 'boletoBancario' }],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'GerouProposta', text: 'Gerou Proposta' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Expirado', text: 'Expirado' },
        ],

        campoSeguradoSelecionado: true,
        valorRenegociacao: formatarValor(0),
        calculou: false,

        // Índices de Referência
        competenciaDaTaxa: null,
        taxaDaCompetencia: null,
      }
    },
    mounted() {
      this.proposta.tipoCalculo = 'boletoBancario'
      this.fetchResults()
      this.proposta = {
        ...this.proposta,
        institutoSelecionado: this.userData.institutoSelecionado,
      }
      this.exibeDadosSegurado = false
      this.proposta.parcelas = this.proposta.parcelaMaxima

      if (this.parametro.origem == 'exoneracaoPendentes') {
        this.regrasValidacaoParcelas = {
          required: true,
          parcela: this.parametro.parcela.length,
          zero: 0,
        }
        this.habilitaCampoValorRescisao = true
        this.valorRenegociacao = formatarValor(0)
        this.proposta.parcelas = 0
      } else {
        this.regrasValidacaoParcelas = {}
        this.proposta.parcelas = this.parametro.parcela.length

        let parcelasAntecipadas = this.parametro.parcela.map((parcela) => {
          return {
            id: parcela.id,
            numeroParcela: parcela.parcela,
            valorAntecipacao: parcela.valorAntecipacao,
            valorPago: 0,
          }
        })

        this.valorTotalParcelas = parcelasAntecipadas.reduce(
          (accumulator, currentValue) => formatarParaNumber(accumulator) + formatarParaNumber(currentValue.valorAntecipacao),
          0,
        )

        this.totalRowsParcelas = parcelasAntecipadas.length
        this.valorTotalParcelas = formatarValor(this.valorTotalParcelas)

        this.itemsParcelas = parcelasAntecipadas
        this.habilitaCampoValorRescisao = false
        this.valorRenegociacao = formatarValor(
          this.parametro.parcela.reduce((acc, cur) => acc + formatarParaNumber(cur.valorAntecipacao), 0),
        )
      }
    },
    methods: {
      habilitarBotaoProximoCalcular(aba) {
        if (aba === 1 && this.parametro.origem == 'exoneracaoPendentes') {
          return this.calculou === true
        } else {
          return true
        }
      },
      calcular() {
        if (!this.proposta.valorRescisao) {
          this.$message.error('Erro: Valor da renegociação é obrigatório para o cálculo.')
          return
        }

        // Ordenando as parcelas em ordem decrescente de 'parcela'
        this.parametro.parcela.sort((a, b) => b.parcela - a.parcela)

        let valorRestante = formatarParaNumber(this.proposta.valorRescisao)
        const parcelasPagaveis = []

        for (let parcela of this.parametro.parcela) {
          if (valorRestante >= parcela.valorAntecipacao) {
            // Se o valor restante cobre o valor da parcela, adicionamos à lista
            parcelasPagaveis.push({ ...parcela, valorPago: parcela.valorAntecipacao })
            valorRestante -= parcela.valorAntecipacao
          } else if (valorRestante > 0) {
            // Pagamento parcial da última parcela possível
            parcelasPagaveis.push({ ...parcela, valorPago: valorRestante })
            valorRestante = 0 // Todo o valor disponível foi utilizado
            break // Encerramos o loop
          } else {
            // Não há mais valor disponível
            break
          }
        }

        this.calculou = true
        this.valorRenegociacao = formatarValor(parcelasPagaveis.reduce((acc, cur) => acc + cur.valorPago, 0))
        this.proposta.parcelas = parcelasPagaveis.length
        this.parametro.parcela = parcelasPagaveis
        this.fieldsParcelas = [
          { key: 'parcela', label: 'Parcela' },
          { key: 'valorAntecipacao', label: 'Valor da Antecipação' },
          { key: 'valorPago', label: 'Valor Pago' },
        ]
        this.itemsParcelas = parcelasPagaveis
        this.totalRowsParcelas = parcelasPagaveis.length
        this.valorTotalParcelas = formatarValor(
          parcelasPagaveis.reduce(
            (accumulator, currentValue) => formatarParaNumber(accumulator) + formatarParaNumber(currentValue.valorPago),
            0,
          ),
        )
      },
      fetchResults() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.carregandoDadosIniciais = true
          useJwt
            .pesquisar('renegociacao/GetSimulacaoRenegociacaoSegurados', {
              cpfTomador: this.parametro.segurado.tomador.cpf,
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              this.getSuggestionValue(response.data)
              this.carregandoDadosIniciais = false
            })
            .catch((error) => {
              console.error(error)
              this.carregandoDadosIniciais = false
            })
        }, this.debounceMilliseconds)
      },
      getSuggestionValue(suggestion) {
        const item = suggestion[0]
        if (!item?.grupoFolhaId) {
          this.$swal({
            title: 'Atenção!',
            text: 'O segurado não possui grupo folha cadastrado.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        } else {
          this.campoSeguradoSelecionado = false
          this.exibeDadosSegurado = true
          this.segurado.id = item.id
          this.segurado.tomador.cpf = item.tomador.cpf
          this.segurado.tomador.nomeCompleto = item.tomador.nomeCompleto
          this.segurado.tomador.nomeSocial = item.tomador.nomeSocial
          this.segurado.tomador.dataNascimento = formatarData(item.tomador.dataNascimento)
          this.segurado.categoria = item.situacaoPrevidenciaria
          this.segurado.remuneracaoProventos = formatarValor(item.remuneracao)
          this.segurado.margemUtilizada = formatarValor(item.margemUtilizada)
          this.segurado.margemDisponivel = formatarValor(item.margemDisponivel)
          this.proposta.seguradoSelecionado = item

          return item.nomeCompleto
        }
      },
      validationDadosSegurado() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tomRules.validate().then(async (success) => {
            if (success && this.segurado?.tomador?.nomeCompleto) {
              resolve(true)
            } else {
              let title
              if (!success) title = 'Alerta! Há campos não preenchidos no formulário.'
              else title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationDadosConsignacao() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.dadosRules.validate().then((success) => {
            if (success) {
              resolve(true)
              if (!this.parametro.origem == 'exoneracaoPendentes') {
                this.gerarParcelas()
              }
            } else {
              reject()
            }
          })
        })
      },
      validationTabelaParcelas() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.bankRules.validate().then((success) => {
            if (success) {
              resolve(true)
            } else {
              let title = 'Erro: Há campos não preenchidos no formulário.'
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      async gerarParcelas() {
        this.isBusy = true

        let parcelasAntecipadas = []
        let parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.parametro.id,
          quantidadeDeParcelas: this.parametro.parcela.length,
        }

        await useJwt
          .post('renegociacao/GetCalcularParcelasAAntecipar', parametro)
          .then((response) => {
            if (response.data?.status == 'erro') {
              this.isBusy = false
              console.error(response.data.mensagem)
              this.$message.error('Erro ao calcular antecipações')
              return
            }

            parcelasAntecipadas = response.data
            parcelasAntecipadas = parcelasAntecipadas.reverse()
            this.isBusy = false
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao calcular antecipações')
          })

        this.itemsParcelas = parcelasAntecipadas
        this.totalRowsParcelas = this.itemsParcelas.length
        this.valorTotalParcelas = formatarValor(
          this.itemsParcelas.reduce(
            (accumulator, currentValue) => formatarParaNumber(accumulator) + formatarParaNumber(currentValue.valorAntecipacao),
            0,
          ),
        )
      },
      async salvarRenegociacao() {
        const proposta = this.proposta
        await this.buscaCompetenciaTaxa()
        let navegadorUtilizado = detectarNavegador()

        if (this.cadastroFinalizado == false) this.cadastroFinalizado = true
        else return

        if (!proposta.status) {
          let dataToSave = {
            ...proposta,
            valorPrimeiraParcela: formatarParaNumber(proposta.valorPrimeiraParcela),
            taxa: formatarParaNumber(proposta.taxa),
            taxaDaCompetencia: this.taxaDaCompetencia,
            competenciaDaTaxa: this.competenciaDaTaxa,
            quantidadeParcelas: formatarParaNumber(this.proposta.parcelas),
            financeiroId: this.parametro.id,
            valorTotalParcelas: formatarParaNumber(this.valorTotalParcelas),
            valor: formatarParaNumber(this.valorTotalParcelas),
            seguradoId: proposta.seguradoSelecionado.id,
            usuarioAprovadoId: this.userData.id,
            dataRenegociou: formatarData(new Date()),
            propostaId: this.parametro.propostaId,
            primeiraParcela: proposta.primeiraParcela,
            parcelas: this.itemsParcelas,
            institutoId: this.userData.institutoSelecionado,
            navegadorUtilizado: navegadorUtilizado,
            valorContrato: formatarParaNumber(this.parametro.valorContrato),
          }

          const { segurado, seguradoSelecionado, ...data } = { ...dataToSave }
          const seguradoEhFalecido = this.parametro.segurado.tomador.falecido
          const seguradoEhExonerado = this.parametro.segurado.exonerado

          let link
          if (seguradoEhExonerado) link = 'renegociacao/NovaRenegociacaoExonerados'
          else if (seguradoEhFalecido) link = 'renegociacao/NovaRenegociacaoFalecidos'
          else link = 'renegociacao/gerarRenegociacao'

          useJwt
            .post(link, data)
            .then((response) => {
              if (response.data.status == 'erro') {
                this.$swal({
                  title: 'Atenção!',
                  text: response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    this.loading = !this.loading
                    this.$emit('closeGeracao')
                  }
                })
                return
              }

              this.$swal({
                title: 'Atenção!',
                text: 'Renegociação gerada com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                this.loading = !this.loading
                this.$emit('closeModalCadastro')
              })
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        }
      },
      async buscaCompetenciaTaxa() {
        return new Promise(async (resolve, reject) => {
          await useJwt
            .get(`configuracoes/indices/FindOne/${this.userData.Instituto.tipoIndiceSelecionado}`)
            .then((response) => {
              this.competenciaDaTaxa = response.data.competencia
              this.taxaDaCompetencia = response.data.taxa
              resolve()
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Houve um erro ao consultar a taxa da competência.')
              reject()
            })
        })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  pre {
    min-height: 295px;
    padding: 1.5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }

  .autosuggest__results-item {
    cursor: pointer;
  }

  #radiosFormasDeCalculo,
  #radiosTipoDeCalculo {
    display: flex;
  }

  #radiosFormasDeCalculo label span {
    white-space: nowrap;
  }
</style>
