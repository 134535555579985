<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Pesquisa">
          <b-row>
            <b-col md="2">
              <b-form-group label="Pesquisar Por" label-for="opcao">
                <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input
                  id="descricao"
                  v-model="pesquisar.descricao"
                  placeholder="Descrição"
                  v-on:keydown.enter="carregarGrid"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 24px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-show="items.length > 0">
      <b-col class="w-100">
        <b-card>
          <b-table
            striped
            class="responsive"
            :per-page="pesquisar.perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isBusy"
            :current-page="pesquisar.currentPage"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>

            <template v-slot:[`cell(segurado.situacaoPrevidenciaria)`]="row">
              <b-badge pill variant="primary" class="mr-1">
                {{ formatarInativoAposentado(String(row.item.segurado.situacaoPrevidenciaria)) }}
              </b-badge>
            </template>

            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>

                <b-dropdown-item @click="visualizarRenegociacao(row.item)">
                  <feather-icon icon="EyeIcon" size="16" />
                  <span class="align-middle ml-50">Visualizar Renegociação</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="aprovarRenegociacao(row.item.id)"
                  v-if="row.item.statusRenegociacao == 'Pendente'"
                  v-show="acesso.RenegociacaoAlterarStatus"
                >
                  <feather-icon icon="CheckSquareIcon" size="16" />
                  <span class="align-middle ml-50">Aprovar Renegociação</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="negarRenegociacao(row.item.id)"
                  v-if="row.item.statusRenegociacao == 'Pendente'"
                  v-show="acesso.RenegociacaoAlterarStatus"
                >
                  <feather-icon icon="XSquareIcon" size="16" />
                  <span class="align-middle ml-50">Negar Renegociação</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0 mt-1">
            <!-- page length -->
            <b-form-group
              style="align-items: center"
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
            </b-form-group>

            <!-- pagination -->
            <b-pagination
              v-model="pesquisar.currentPage"
              :total-rows="totalRows"
              :per-page="pesquisar.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <b-modal
      id="modal-visualizar"
      ref="modal-visualizar"
      centered
      size="xl"
      title="Detalhes dessa Renegociação"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <modal-visualizar-exoneracao-obitos
        ref="simulacaoRef"
        @closeModalVisualizar="fecharModalRenegociacao"
        :parametro="visualizarSelecionada"
      ></modal-visualizar-exoneracao-obitos>
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarInativoAposentado, formatarValor } from '@/utils/Functions/Formatacao/Formatacao'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import renegociacaoDividaVisualizarModal from '@/views/pages/renegociacao/dividas/renegociacaoDivida/renegociacaoDividaVisualizarModal'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required } from '@validations'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import ModalVisualizarExoneracaoObitos from './modalVisualizarExoneracaoObitos.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      renegociacaoDividaVisualizarModal,
      AutenticaSenha,
      formatarData,
      ModalVisualizarExoneracaoObitos,
      formatarInativoAposentado,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarInativoAposentado,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Matrícula' },
          { value: 3, text: 'Nº Contrato' },
        ],
        isBusy: false,
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'codigo', label: 'Nº Contrato' },
          {
            key: 'segurado.tomador.nomeCompleto',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'valorContrato',
            label: 'Valor do Contrato',
            formatter: (value) => (value ? formatarValor(value) : ''),
          },
          { key: 'segurado.matricula', label: 'Matrícula' },
          {
            key: 'segurado.situacaoPrevidenciaria',
            label: 'Categoria',
          },
        ],
        parametroRenegociacao: {},
        parametroParcela: {},
        visualizarSelecionada: { tomador: {} },
        items: [],
        dados: {},
        acesso: {
          RenegociacaoConsultar: null,
          RenegociacaoCriarNovo: null,
          RenegociacaoAlterarStatus: null,
          RenegociacaoVisualizar: null,
        },
        grupoPermissao: {},
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RenegociacaoConsultar = true
        this.acesso.RenegociacaoCriarNovo = true
        this.acesso.RenegociacaoAlterarStatus = true
        this.acesso.RenegociacaoVisualizar = true
      } else {
        this.acesso.RenegociacaoConsultar = this.userData.GrupoAcesso.RenegociacaoConsultar
        this.acesso.RenegociacaoCriarNovo = this.userData.GrupoAcesso.RenegociacaoCriarNovo
        this.acesso.RenegociacaoAlterarStatus = this.userData.GrupoAcesso.RenegociacaoAlterarStatus
        this.acesso.RenegociacaoVisualizar = this.userData.GrupoAcesso.RenegociacaoVisualizar
      }
    },
    methods: {
      pesquisarGrupo() {
        this.carregarGrid()
      },
      authEEdita(item) {
        this.items.item = item
        this.items.mode = 'authEEdita'
        this.$refs['modal-auth-senha'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEEdita') {
            this.editar(this.items.item)
          } else if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      carregarGrid() {
        this.isBusy = true
        const institutoId = this.userData.institutoSelecionado
        let parametros = {
          ...this.pesquisar,
          institutoId,
        }

        useJwt
          .post(`renegociacao/GetPesquisarExoneradosPendentes`, parametros)
          .then((response) => {
            if (response.data?.status == 'erro') {
              this.$message.error(response.data.mensagem)
              this.isBusy = false
              return
            }

            this.items = response.data
            this.totalRows = response.data.length
            this.isBusy = false

            if (this.items.length == 0) {
              this.$message.error('Não foram encontradas dívidas pendentes sobre exonerações!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fecharRenegociacao() {
        this.$refs['modal-renegociacao'].hide()
      },
      visualizarRenegociacao(item) {
        this.visualizarSelecionada = {
          ...item,
          origem: 'exoneracaoPendentes',
        }
        this.$refs['modal-visualizar'].show()
      },
      aprovarRenegociacao() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      negarRenegociacao(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente reprovar renegociação selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/instituto/negarRenegociacao`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      editar(item) {
        useJwt
          .get(`grupo-acesso/editarGrupo/${item}`)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fecharModalRenegociacao() {
        this.$refs['modal-visualizar'].hide()
        this.carregarGrid()
      },
      permissoes(item) {
        // useJwt
        //   .get(`grupo-acesso/editarGrupoPermissoes/${item}`)
        //   .then((response) => {
        //     this.grupoPermissao = response.data;
        //     this.$refs["modal-workflow-modal"].show();
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
      },
    },
  }
</script>
