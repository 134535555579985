<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos Pendentes</span>
        </template>
        <contratos-pendentes />
      </b-tab>
      <b-tab title="Contratos Regularizados">
        <template #title>
          <feather-icon icon="TrendingUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos Regularizados</span>
        </template>
        <contratos-regularizados />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ContratosPendentes from './components/contratosPendentes.vue'
  import ContratosRegularizados from './components/contratosRegularizados.vue'

  export default {
    components: {
      ContratosPendentes,
      ContratosRegularizados,
    },
  }
</script>

ContratosRegularizados
